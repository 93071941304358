import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { About, Footer, Header, Skills, Work } from './container'
import { Navbar, Modal } from './components'
import "./App.scss"
import Media from './container/Media/Media'

const App = () => {
  const [ modalOpen, setModalOpen ] = useState(false);
  const close = () =>{ setModalOpen(false)}
  const open = () =>{ setModalOpen(true)}
  const [ modalData, setModalData ] = useState({
    title: "IOT Joint Recovery Measuring Device",
    descShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.",
    codeLink: "",
    projectLink: "",
    mediaType: "img",
    media: "https://pbs.twimg.com/media/FMY0bCHXMAQm4Mh.jpg",
    mediaImg: "",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    technology: [{name:"Node JS", img:"https://cdn.iconscout.com/icon/free/png-256/node-js-1174925.png"},{name:"Node JS", img:"https://cdn.iconscout.com/icon/free/png-256/node-js-1174925.png"},{name:"Node JS", img:"https://cdn.iconscout.com/icon/free/png-256/node-js-1174925.png"}]
  })

  return (
    <div className='app'> 
        <Navbar />
        <Header />
        <Work modalOpen={modalOpen} close={close} open={open} setModalData={setModalData}/>
        <Skills />
        <About />
        <Media />
        <Footer />
        <AnimatePresence
          initial={false}
          exitBeforeEnter={true}
          onExitComplete={()=> null}
        >
          { modalOpen && <Modal modalOpen={modalOpen} handleClose={close} modalData={modalData}/>}
        </AnimatePresence>

    </div>
  )
}

export default App