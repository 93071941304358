import React, { useState } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion'
import { urlFor } from '../../client';
import Backdrop from '../Backdrop/Backdrop';
import './Modal.scss';

const Modal = ({handleClose, modalData}) => {

    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0
        },
        visible: {
            y: "0",
            opacity:10,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500
            }
        },
        exit: {
            y: "100vh",
            opacity: 0
        }
    }

    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className='modal__header'>
                    <div className='header__flex'>
                        <h1 className='modal__title'>{modalData.title}
                            <a href={modalData.projectLink} className='nostyle' target="_blank" rel="noreferrer">
                                <div className="modal__icon app__flex" >
                                    <AiFillEye />
                                </div>
                            </a>
                            <a href={modalData.codeLink} className='nostyle'target="_blank" rel="noreferrer">
                                <div className="modal__icon app__flex" >
                                    <AiFillGithub />
                                </div>
                            </a>
                        </h1>
                    </div>   
                    <p className='modal__short-desc'>{modalData.descShort}</p>
                </div>
                <div className='modal__content'>
                    <div className='modal__media'>
                        {(modalData.mediaType === "img") ? <img className='modal__img' src={urlFor(modalData.mediaImg)} alt="" /> : <iframe className="modal__video" width="100%" src={`https://www.youtube.com/embed/${modalData.media}`}> </iframe>}
                    </div>
                </div>
                <div className='modal__content'>
                    <div className='modal__subheader'> About </div>
                    {
                        modalData.desc.split("#").map((text, i)=>{
                            if(text.indexOf("IMG") != -1){
                                return (
                                    <div className='modal__media' key={text+i}>
                                        <img className='modal__img' src={text.split("^")[1]} alt="" />
                                    </div>
                                )
                            }
                            else{
                                return (
                                    <div className='modal__desc' key={text+i}> {text} </div>
                                )                                
                            }

                        })
                    }
                    
                </div>
                <div className='modal__content'>
                    <div className='modal__subheader'> Built Using: </div>
                    <div className='modal__tech'>
                        <div className='grid_wrapper'>
                            {modalData.technology.map((tech, i)=>(
                                <motion.div
                                    className="modal__tech_size app__flex"
                                    key={tech.name + i + "p"}
                                >
                                    <div
                                        className="app__flex"
                                        style={{ backgroundColor: "#fef4f5" }}
                                    >
                                        <img src={urlFor(tech.icon)} alt={tech.name} />
                                    </div>
                                    <p className="p-text">{tech.name}</p>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
                {/*<button onClick={handleClose} >Close</button>*/}
            </motion.div>
        </ Backdrop>
    );
}

export default Modal;