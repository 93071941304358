import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import "./About.scss"
import { urlFor, client  } from '../../client'
import { NavigationDots } from '../../components'

const About = () => {
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type == "abouts"]'
  
    client.fetch(query)
    .then((data)=>{
      setAbouts(data)
    })
  }, [])

  return (
    <motion.div
      whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.5 }}
      className={`app__flex`}
    >
      <div id={"certifications"} className={`container`} >
        <div className="wrap app__flex" >
            <h2 className='head-text'>
              Certifications
            </h2>

            <div className='app__profiles'>
              {abouts.map((about, index)=>(
                <a href={about.url} style={{"text-decoration": "none"}}>
                  <motion.div
                    whileInView={{opacity: 1}}
                    whileHover={{scale: 1.1}}
                    transition={{duration:0.5, type: 'tween'}}
                    className="app__profile-item"
                    key={about.title + index}
                  >
                    <img src={urlFor(about.imgUrl)} alt={about.title} />
                    <h2 className='bold-text' style={{marginTop: 20}}> {about.title}</h2>
                  </motion.div>
                </a>
              ))}
            </div>
        </div>
          <NavigationDots active={"certifications"}/>
      </div>
    </motion.div>
  )
}

export default About;