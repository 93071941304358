import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Media.scss';

const Media = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [animationType, setAnimationType] = useState("in");
  const [brands, setBrands] = useState([]);

  const handleClick = (index, direction) => {
    setAnimationType((direction == "forwards" ? "in" : "out"))
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });

    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);

  return (
    <>
      <h2 style={{"marginBottom": "40px", "marginTop": "0px"}} className="head-text">Media Work</h2>
      <>
        {testimonials.map((testimonial, i) => (
          <motion.div
            variants={{"in" : { x: [-100, 0], opacity: [0, 1]  }, "out" : { x: [100, 0], opacity: [0, 1]  }}}
            animate={(i == currentIndex ? animationType : "")}
            transition={{ duration: 1.0, type: 'tween' }}
            key={testimonial.name + "_" + i}
          >
            <div style={{"display" : (i == currentIndex ? "" : "none")}} className="app__testimonial-item app__flex">
              <div className="app__testimonial-content-left">
                <img className="app__testimonial-logo" src={urlFor(testimonial.logo)} alt={testimonial.name} />
                <div>
                  <h4 className="bold-text">{testimonial.name}</h4>
                  <h5 className="p-text">{testimonial.platform}</h5>
                </div>
              </div>
              <div className="app__testimonial-content">
                <p className="p-text">{testimonial.desc}</p>

              </div>
              <div className="app__testimonial-content">
                <img className="app__testimonial-photo" src={urlFor(testimonial.photo)} alt={testimonial.name} />
                <div className='app__testimonial-link app__flex' >
                  <a className='nostyle' href={testimonial.link}> {testimonial.linkTitle} </a>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
          <div className="app__testimonial-btns app__flex">
            <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1, "backwards")}>
              <HiChevronLeft />
            </div>

            <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1, "forwards")}>
              <HiChevronRight />
            </div>
          </div>
      </>


      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Media, 'app__testimonial'),
  'media',
  'app__primarybg',
);
