import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';

const Skills = () => {
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      const sortedData = data.sort((a, b) => b.year - a.year)
      setExperiences(sortedData);
    });

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, i) => (
            <div
              className="app__skills-item app__flex"
              key={"skill" + i}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience,i) => {
              if(experience.display){
                return (
                  <div
                    className="app__skills-exp-item"
                    key={experience.year + i}
                  >
                    <div className="app__skills-exp-year">
                      <p className="bold-text">{experience.year}</p>
                    </div>
                    <div className="app__skills-exp-works">
                      {experience.works.map((work,i) => {
                        if(work.display){
                          return (
                            <div
                              className="app__skills-exp-work"
                              data-tip
                              data-for={work.name}
                              key={work.name + i}
                            >
                              <h4 className="bold-text"><a className="nostyle" style={{"cursor": "pointer"}} href={work.link}>{work.company}</a></h4>
                              <p className="p-text" style={{"cursor": "auto"}}>{work.name}</p>
                              <ul>
                              {work.roles.map((role, i) => ( 
                                <li style={{"cursor": "auto"}} key={role+i}> 
                                  {role} 
                                </li>
                              ))}
                              </ul>
                            </div>        
                          )
                        }
                        else{
                          return(<div key={work.name + i}></div>)
                        }
                      })}
                    </div>
                  </div>
                )
              }
              else {
                return(<div key={experience.year + i}></div>)
              }
            }
          )}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
