import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ to_name: '', from_email: '', html_message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  function sendEmail(e) {
    e.preventDefault();  
    setLoading(true);
    
    const email = {
      to_name: 'John',
      from_name: formData.username,
      from_email: formData.email,
      html_message: formData.message,
    };

    console.log(e.target)

    emailjs.sendForm('service_zqp4rwx', 'template_7zgmdzr', e.target, 'fODvBPjH7cI4yTvTg')
      .then((result) => {
          //window.location.reload()  
          setLoading(false);
          setIsFormSubmitted(true);
      }, (error) => {
          console.log(error.text);
      });
  }

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Get in touch!</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:johncomonitski@gmail.com" className="p-text">JohnComonitski@gmail.com</a>
        </div>

      </div>
      {!isFormSubmitted ? (
        <form className="app__footer-form app__flex contact-form" onSubmit={sendEmail} >
          <div className="app__flex" style={{"display": "none"}}>
              <input className="p-text" type="text" placeholder="Your Name" name="to_name" value={"John"} onChange={()=>{}}/>
            </div>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="Your Name" name="from_name" value={username} onChange={handleChangeInput} />
            </div>
            <div className="app__flex">
              <input className="p-text" type="email" placeholder="Your Email" name="from_email" value={email} onChange={handleChangeInput} />
            </div>
            <div>
              <textarea
                className="p-text"
                placeholder="Your Message"
                value={message}
                name="html_message"
                onChange={handleChangeInput}
              />
            </div>
            <button type="submit" className="p-text" > {!loading ? 'Send Message' : 'Sending...'} </button>
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
