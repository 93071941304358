import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Work.scss';

const Work = (props) => {
  console.log(props)
  const { modalOpen, open, close, setModalData } = props;
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Best');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "works"]';
    client.fetch(query).then((data) => {
      var tmp = []
      for(var i = 0; i < data.length; i++){
        if(!data[i]._id.includes('drafts')){
          tmp.push(data[i])
        }
      }

      setWorks(tmp);
      setFilterWork(tmp.filter((tmp) => tmp.tags.includes("Best")));
    });
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  const HandleClick = (work) => {
    if(modalOpen){
      close()
    }
    else{
      setModalData({
        title: work.title,
        descShort: work.descriptionShort,
        codeLink: work.codeLink,
        projectLink: work.projectLink,
        mediaType: work.mediaType,
        media: work.media,
        mediaImg: work.mediaImg,
        desc: work.descriptionLong,
        technology: work.tech
      })
      open()
    }
  }

  return (
    <motion.div
      whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.5 }}
      className={`app__works app__flex`}
      id="projects"
    >
      <h2 className="head-text" >My Projects</h2>

      <div className="app__work-filter">
        {['Best', 'ML', 'AWS', 'React JS', 'Node JS', 'IOT', 'JS', 'Python', 'GSC/C++', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => {
            return ( 
              <div className="app__work-item app__flex" key={index} onClick={ () => {HandleClick(work)}} >
                <div
                  className="app__work-img app__flex"
                >
                  <img src={urlFor(work.imgUrl)} alt={work.name} />
                </div>

                <div className="app__work-content app__flex">
                  <h4 className="bold-text">{work.title}</h4>
                  <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>
                </div>
              </div>
            )
          }
        )}
      </motion.div>
    </motion.div>
  );
};

export default Work;
